<template>
  <div style="text-align: center;position:relative;">
    <span style="display: inline-block;">{{ `${dates} - ${item.nights} Ng` }}</span>
    <span>&nbsp;:&nbsp;</span>
    <span style=" display: inline-block;" v-if="adult1.active">
      <span>&nbsp;{{ `${adult1.roomType} - ${adult1.price}$` }}</span>
    </span>
    <span v-if="adult1.active">&nbsp;/</span>
    <span style=" display: inline-block;" v-if="adult2.active">
      <span>&nbsp;{{ `${adult2.roomType} - ${adult2.price}$*2` }}</span>
    </span>
    <span style=" display: inline-block;" v-if="adult21.active">
      <span>&nbsp;{{ `/ ${adult21.roomType}(2+1) - ${adult21.price}$*3` }}</span>
    </span>
    <span style=" display: inline-block;" v-if="adult3.active">
      <span>&nbsp;{{ `/ ${adult3.roomType} - ${adult3.price}$*3` }}</span>
    </span>
    <span style=" display: inline-block;" v-if="adult22.active">
      <span>&nbsp;{{ `/ ${adult22.roomType}(2+2) - ${adult22.price}$*4` }}</span>
    </span>
    <span style=" display: inline-block;" v-if="adult23.active">
      <span>&nbsp;{{ `/ ${adult23.roomType}(2+3) - ${adult23.price}$*5` }}</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const { VUE_APP_MAIN_DOMAIN } = process.env;

export default {
  name: 'price-plan-send-item',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    chosenRoomTypes: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      searchData: 'GET_SEARCH_INFORMATION',
    }),
    adult23() {
      return this.getSpecificItem(2, 3);
    },
    adult22() {
      return this.getSpecificItem(2, 2);
    },
    adult3() {
      return this.getSpecificItem(3, 0);
    },
    adult21() {
      return this.getSpecificItem(2, 1);
    },
    adult2() {
      return this.getSpecificItem(2, 0, 'DB');
    },
    adult1() {
      return this.getSpecificItem(1, 0, 'SG');
    },
    dates() {
      const { item } = this;
      // const { arrivalDate, departureDate } = item;
      const { departureDate } = item;
      // return `${departureDate.split('-').reverse().splice(0, 2).join('.')} - ${arrivalDate.split('-').reverse().splice(0, 2).join('.')}`;
      return `${departureDate.split('-').reverse().splice(0, 2).join('.')}`;
    },
  },
  data() {
    return {
      baseDomain: VUE_APP_MAIN_DOMAIN,
    };
  },
  methods: {
    getSpecificItem(cAdult, cChild, roomTypeCode) {
      const { item } = this;
      const { roomRates } = item;
      const { pricePlanChangeInfo } = item;
      const oldRoomRates = pricePlanChangeInfo?.oldRoomRates || null;
      let oneItem = null;
      if (roomTypeCode) {
        oneItem = roomRates.filter((rate) => rate.roomTypeCode === roomTypeCode)?.sort((a, b) => a.avgPrice - b.avgPrice)[0];
      } else {
        oneItem = roomRates.filter((rate) => rate.adults === cAdult && rate.children === cChild)?.sort((a, b) => a.avgPrice - b.avgPrice)[0];
      }
      const oldItem = oldRoomRates ? oldRoomRates.filter((rate) => rate.adults === cAdult && rate.children === cChild)?.sort((a, b) => a.avgPrice - b.avgPrice)[0] : null;
      const changedPriceValue = oldItem ? oldItem.avgPrice - oneItem.avgPrice : 0;
      const changeType = pricePlanChangeInfo?.changeType || null;

      return {
        active: !!oneItem && this.chosenRoomTypes.includes(`${oneItem.adults},${oneItem.children},${oneItem.roomTypeCode}`),
        price: oneItem?.avgPrice,
        classCode: oneItem?.roomClassCode,
        roomType: oneItem?.roomTypeCode,
        changedPriceValue,
        grossRate: oneItem?.avgPriceBd || '',
        changeType,
      };
    },
  },
};
</script>

<style scoped>
  table td div, table td p {
    line-height: 1rem;
  }
</style>
